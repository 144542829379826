<template>
  <div class="card-body">
    <h5
      class="d-flex"
      :class="{
        'text-lg-left': !isRight,
        'text-lg-right': isRight,
      }"
    >
      <img
        class="img-icon d-flex"
        :class="{
          'mr-2': !isRight,
          'ml-2': isRight,
        }"
        src="@/assets/images/icon-payment.png"
        alt="img-payment"
      />

      <span class="d-flex">
        {{ $t("summary.bank-details.header") }}
      </span>
    </h5>

    <div class="row form-group mb-3 mb-sm-2">
      <div class="col-12 col-sm-5">
        <label>{{ $t("summary.bank-details.account-number") }}</label>
      </div>

      <div class="col-12 col-sm-7 p-sm-0">
        <strong>{{ data.accountNumber || "-" }}</strong>
      </div>
    </div>

    <div class="row form-group mb-3 mb-sm-2">
      <div class="col-12 col-sm-5">
        <label>{{ $t("summary.bank-details.bank-code-swift") }}</label>
      </div>

      <div class="col-12 col-sm-7 p-sm-0">
        <strong>{{ data.bankCodeSwift || "-" }}</strong>
      </div>
    </div>

    <div class="row form-group mb-3 mb-sm-2">
      <div class="col-12 col-sm-5">
        <label>{{ $t("summary.bank-details.branch-code") }}</label>
      </div>

      <div class="col-12 col-sm-7 p-sm-0">
        <strong>{{ data.branchCode || "-" }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VBankDetails",

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
